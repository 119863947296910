import React from 'react'

// Components
import UspDefault from 'components/flex/Usp/UspDefault'

interface UspShellProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Usp
  location?: any
}

interface UspProps {
  [key: string]: any
}

const UspShell: React.FC<UspShellProps> = ({ fields, location = {} }) => {
  const usp: UspProps = {
    default: UspDefault,
  }

  if (!fields.styletype || !usp[fields.styletype]) {
    return null
  }

  const Component = usp[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default UspShell
