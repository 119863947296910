import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'

const Section = styled.section`
  background: ${({ theme }) => theme.color.grey};
`

const Usp = styled.div`
  @media (min-width: 1200px) {
    padding-right: 4rem;
  }
`

const Image = styled(Plaatjie)`
  width: 54.24px;
  height: 54.24px;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 20px;
    line-height: 25.76px;
    & strong {
      line-height: 25.76px;
    }
  }
`

interface UspDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Usp
}

const UspDefault: React.FC<UspDefaultProps> = ({ fields }) => (
  <Section className="mb-5 py-5">
    <div className="container py-md-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="row">
            {fields.usps?.map((usp, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="col-md-4 mb-5 mb-md-0" key={index}>
                <Usp>
                  <div className="d-flex justfiy-content-between align-items-center mb-4">
                    <Image image={usp?.icon} alt="" className="me-3" />
                    <Content content={usp?.title} />
                  </div>
                  <Content content={usp?.description} />
                </Usp>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default UspDefault
